<template>
    <div>
        <NavOnlineAdmission></NavOnlineAdmission>
        <b-container>
            <b-row class="mt-5 justify-content-center">

                <b-modal v-model="isModalVisible" hide-footer>
                    <h2 class="text-center text-danger">{{ errorMessage }}.</h2>
                </b-modal>
                <SideNavOnlineAdmission :institute="institute" :formId="formId" :temporaryId="null" ></SideNavOnlineAdmission>

                <b-col cols="12" md="5" class="">
                    <b-card>
                        <b-card-body>
                            <b-form-group
                                label="Would you like to start a new application or continue from a previous draft? You can create a new application or select 'Resume Draft' to continue your previous application."
                                label-for="radio-group">
                                <b-form-radio-group v-model="newApplication" id="radio-group" class="mt-1">
                                    <b-form-radio value="yes">New Application</b-form-radio>
                                    <b-form-radio value="no"> Resume Draft Application</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>

                            <div v-show="newApplication == 'yes'">
                                <b-form-group label-for="name">
                                    <template v-slot="label">
                                        <label>Name <span class="text-danger">*</span></label>
                                        <b-form-input id="name" v-model="formData.name"
                                            placeholder="Enter applicant name" />
                                        <b-form-invalid-feedback :state="nameState">
                                            <span v-if="errors.name">{{ errors.name[0] }}</span>
                                        </b-form-invalid-feedback>
                                    </template>
                                </b-form-group>

                                <b-form-group label-for="gender">
                                    <template v-slot="label">
                                        <label>Gender <span class="text-danger">*</span></label>
                                        <b-form-select v-model="formData.gender" :options="genders"></b-form-select>
                                        <b-form-invalid-feedback :state="genderState">
                                            <span v-if="errors.gender">{{ errors.gender[0] }}</span>
                                        </b-form-invalid-feedback>
                                    </template>
                                </b-form-group>

                                <b-form-group label-for="mobile_no">
                                    <template v-slot="label">
                                        <label>Mobile <span class="text-danger">*</span></label>
                                        <b-form-input type="number" id="mobile_no" v-model="formData.mobile_no"
                                            placeholder="Enter applicant mobile number" />
                                        <b-form-invalid-feedback :state="mobileNoState">
                                            <span v-if="errors.mobile_no">{{ errors.mobile_no[0] }}</span>
                                        </b-form-invalid-feedback>
                                    </template>
                                </b-form-group>

                                <b-form-group label-for="dobReg">
                                    <template v-slot="label">
                                        <label>Date of Birth <span class="text-danger">*</span></label>
                                        <b-form-datepicker id="dobReg" v-model="formData.dob" class="mb-2"
                                            placeholder="Enter applicant date of birth"></b-form-datepicker>
                                        <b-form-invalid-feedback :state="dobState" class="dob-error">
                                            <span v-if="errors.dob">{{ errors.dob[0] }}</span>
                                        </b-form-invalid-feedback>
                                    </template>
                                </b-form-group>

                                <b-button variant="primary" class="mt-2" @click="submitForm">Create New
                                    Application</b-button>

                            </div>

                            <div v-show="newApplication == 'no'">
                                <b-form-group label-for="temporaryId">
                                    <template v-slot="label">
                                        <label>Temporary Id <span class="text-danger">*</span></label>
                                        <b-form-input id="temporaryId" v-model="applicationData.temporaryId"
                                            placeholder="Enter applicant temporary id" />
                                        <b-form-invalid-feedback :state="temporaryIdValidation">
                                            Please enter temporary id.
                                        </b-form-invalid-feedback>
                                    </template>
                                </b-form-group>

                                <b-form-group label-for="dob">

                                    <template v-slot="label">
                                        <label>Date of Birth <span class="text-danger">*</span></label>
                                        <b-form-datepicker id="dob" v-model="applicationData.dob" class="mb-2"
                                            placeholder="Enter applicant date of birth"></b-form-datepicker>
                                        <b-form-invalid-feedback :state="dobValidation" class="dob-error">
                                            please enter date of birth.
                                        </b-form-invalid-feedback>
                                    </template>
                                </b-form-group>

                                <b-button variant="primary" @click="searchForm">Resume Draft</b-button>

                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
  
<script>
import {
    BContainer, BCard, BCardBody, BCardGroup, BFormGroup, BFormInput, BButton, BRow,
    BFormDatepicker, BFormRadioGroup, BFormRadio, BFormInvalidFeedback, BIcon, BListGroup, BListGroupItem, BCol, BFormSelect
} from 'bootstrap-vue'
import NavOnlineAdmission from '@/views/components/nav/NavOnlineAdmission.vue';
import SideNavOnlineAdmission from '@/views/components/nav/SideNavOnlineAdmission.vue';
import axios from 'axios';

export default {
    components: {
        BContainer,
        BCard,
        BCardBody,
        BCardGroup,
        BFormGroup,
        BFormInput,
        BButton,
        BRow,
        BFormDatepicker,
        BFormRadioGroup,
        BFormRadio,
        NavOnlineAdmission,
        BFormInvalidFeedback,
        BIcon,
        BListGroup,
        BListGroupItem,
        BCol,
        SideNavOnlineAdmission,
        BFormSelect
    },
    data() {
        return {
            formId: this.$route.params.formId,
            institute: this.$route.params.institute,
            nameState: false,
            mobileNoState: false,
            dobState: false,
            genderState: false,
            temporaryIdValidation: true,
            dobValidation: true,
            isModalVisible: false,
            status: '',
            errorMessage: '',
            newApplication: 'yes',
            genders: [
                { value: 'null', text: 'Please Select Gender' },
                { value: 'male', text: 'Male' },
                { value: 'female', text: 'Female' },
                { value: 'other', text: 'Other' }
            ],
            applicationData: {
                temporaryId: '',
                dob: ''
            },
            formData: {
                name: '',
                gender: null,
                mobile_no: '',
                dob: '',
                institution_id: null,
                instituteSlug: this.$route.params.institute,
                branch_id: null,
                sec_aca_session_id: null,
                adm_admission_form_id: null,

            },
            errors: []
        }
    },

    created() {
        this.getSingleForm();
    },

    methods: {
        getSingleForm() {
            let institute = this.$route.params.institute;
            let formId = this.$route.params.formId;
            axios.get(process.env.VUE_APP_API_URL + `get-single-form/${institute}/${formId}`)
                .then(response => {
                    this.formData.institution_id = response.data.data.institution_id
                    this.formData.branch_id = response.data.data.branch_id
                    this.formData.sec_aca_session_id = response.data.data.sec_aca_session_id
                    this.formData.adm_admission_form_id = response.data.data.form_id
                })
                .catch(error => {
                    console.log(error);
                });
        },
        searchForm() {
            this.errors = [];
            if (this.validateSearchForm()) {
                axios.post(process.env.VUE_APP_API_URL + 'search-by-temporary-id', this.applicationData)
                    .then(response => {
                        // this.$store.dispatch('onlineAdmission/institute', this.$route.params.institute);
                        // this.$store.dispatch('onlineAdmission/temporaryId', response.data.data.temporaryId);
                        let institute = this.$route.params.institute;
                        let formId = this.$route.params.formId;
                        let temporaryId = response.data.data.temporaryId;
                        let paymentStatus = response.data.data.paymentStatus;

                        if (paymentStatus == 1) {
                            this.isModalVisible = true;
                            this.errorMessage = 'You have already paid for this form';
                            return;
                        }
                        localStorage.setItem('applicantId', response.data.data.id);
                        this.$router.push({ path: `/admission/${institute}/${formId}/temporaryId/${temporaryId}`, });
                    })
                    .catch(error => {
                        this.status = error.response.data.status;
                        this.errorMessage = error.response.data.message;
                        this.isModalVisible = true;
                    });
            }
        },

        validateSearchForm() {
            let isValid = true;

            if (this.applicationData.temporaryId.trim() === '') {
                this.temporaryIdValidation = false;
                isValid = false;
            } else {
                this.temporaryIdValidation = true;
            }

            if (this.applicationData.dob.trim() === '') {
                this.dobValidation = false;
                isValid = false;
            } else {
                this.dobValidation = true;
            }

            return isValid;
        },

        submitForm() {
            this.temporaryIdValidation = true;
            this.dobValidation = true;
            axios.post(process.env.VUE_APP_API_URL + 'online-form-submit', this.formData)
                .then(response => {
                    const responseData = response.data.data;
                    let institute = this.$route.params.institute;
                    let temporaryId = response.data.data.temp_id;
                    let formId = this.$route.params.formId;
                    localStorage.setItem('applicantId', response.data.data.id);
                    // this.$store.dispatch('onlineAdmission/updateApplicantData', responseData);
                    this.$router.push({ path: `/admission/${institute}/${formId}/temporaryId/${temporaryId}`, });
                    // console.log('POST request success:', response.data);
                })
                .catch(error => {
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors;

                        const fieldStateMap = {
                            name: 'nameState',
                            mobile_no: 'mobileNoState',
                            dob: 'dobState',
                            gender: 'genderState',
                        };

                        for (const field in fieldStateMap) {
                            if (this.errors[field]) {
                                this[fieldStateMap[field]] = false;
                            }
                        }
                    }

                    // console.error('POST request error:', error.response.data.errors);
                });
        },



    },
    directives: {

    },
    props: {

    },
}
</script>
<style>
.dob-error {
    margin-top: -1.10rem;
}
</style>
  